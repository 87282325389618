<template>
  <div >
    <!-- <IndexHeader></IndexHeader> -->
    <!-- <Nav></Nav> -->
    <EsConnection></EsConnection>
    <!-- <FooterComponent class="foot"></FooterComponent> -->
  </div>
</template>

<script>
  // import Nav from "@/components/nav";
  // import IndexHeader from "@/components/index/indexHeader";
  // import FooterComponent from "@/components/index/homePageComponent/footerComponent";
  import EsConnection from "./components/esConnection";
  export default {
    name: "qxt_index",
    components: {EsConnection},
    mounted(){},
  }
</script>
